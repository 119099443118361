// src/ToneButton.js

import React, { useState } from 'react';

const ToneButton = () => {
    const [toneIndex, setToneIndex] = useState(0);
    const tones = [
        'apex_legends_knockdown.mp3',
        'bumbum_granada.mp3',
        'chicken_dance.mp3',
        'chicken_song.mp3',
        'drunk_man.mp3',
        'huh.mp3',
        'monkey_sound.mp3',
        'squeaky_squeaky_toy.mp3',
    ];

    const playTone = () => {
        const currentTone = tones[toneIndex % tones.length]; // Cycle through the tones
        const audio = new Audio(`/sounds/${currentTone}`);
        audio.play();

        audio.onended = () => {
            setToneIndex(toneIndex + 1); // Move to the next tone after one finishes playing
        };
    };

    return (
        <button className="tone-button" onClick={playTone}>Play a Tone</button>
    );
};

export default ToneButton;
